
  import ScreenSize from '@/mixins/ScreenSize';
  export default {
    name: 'CookieConsentBanner',
    mixins: [ScreenSize],
    watch: {
      '$route.query.showCookieConsent': function (newValue) {
        if (newValue === '1') {
          this.$store.dispatch('cookieConsent/showCookieConsentBanner');
        }
      },
    },
    mounted() {
      this.$store.dispatch('cookieConsent/checkCookieConsent');
    },
    methods: {
      acceptAllCookies() {
        this.$store.dispatch('cookieConsent/acceptAllCookies');
      },
      acceptRequiredCookies() {
        this.$store.dispatch('cookieConsent/acceptRequiredCookies');
      },
    },
  };
